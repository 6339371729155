import React, { FC } from "react"
import Social from "../Social"
import "./Text.css"

const Text: FC<any> = () => {
  return (
    <>
      <div className="container mx-auto">
        <div className="text-white slide-right">
          <p className="text-center sm:text-left text-sm my-3 mx-18 sm:mx-16">
            Hello, I'm
          </p>
          <h3 className="text-center sm:text-left font-bold text-5xl my-2 mx-16 sm:mx-12">
            Sanzio Monti
          </h3>
          <p className="text-center sm:text-left text-md my-3 mx-16 sm:mx-12">
            Data Scientist & Developer
          </p>
        </div>
        <Social />
      </div>
    </>
  )
}

export default Text
