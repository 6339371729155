import React, { useState } from "react"
import useDocumentScrollThrottled from "../../helpers/handleDocumentScrolling"
import "./Header.css"

function Header() {
  const [shouldHideHeader, setShouldHideHeader] = useState(false)
  const [shouldShowShadow, setShouldShowShadow] = useState(false)

  const MINIMUM_SCROLL = 80
  const TIMEOUT_DELAY = 400

  useDocumentScrollThrottled((callbackData: any) => {
    const { previousScrollTop, currentScrollTop } = callbackData
    const isScrolledDown = previousScrollTop < currentScrollTop
    const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL

    setShouldShowShadow(currentScrollTop > 2)

    setTimeout(() => {
      setShouldHideHeader(isScrolledDown && isMinimumScrolled)
    }, TIMEOUT_DELAY)
  })

  const shadowStyle = shouldShowShadow ? "shadow" : ""
  const hiddenStyle = shouldHideHeader ? "hidden" : ""

  return (
    <header
      className={`header ${shadowStyle} ${hiddenStyle} sm:justify-center`}
    >
      <div className="mt-0 mb-0 ml-8 mr-8 text-white">
        <svg
          className="fill-current w-10"
          version="1.1"
          viewBox="0 0 133.25 60.568"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g transform="translate(-83.375 -73.351)">
            <path d="m156.06 73.351h12.113v60.568h-12.113zm48.455 0h-6.0566v12.115h6.0566v48.453h12.113v-60.568zm-121.14 48.455h60.568v12.113h-60.568zm0-12.113h48.455v6.0566h12.113v-18.17h-60.568zm90.852-36.342v12.115h6.0566v48.453h12.115v-60.568zm-90.852 12.115v6.0566h12.113v-6.0566h48.455v-12.115h-60.568z" />
          </g>
        </svg>
      </div>
    </header>
  )
}

export default Header
