import React, { FC } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FaLinkedinIn } from "@react-icons/all-files/fa/FaLinkedinIn"
import { FaGithub } from "@react-icons/all-files/fa/FaGithub"

const Social: FC<any> = (props) => {
  const { site } = useStaticQuery(query)
  const { github, linkedin } = site.siteMetadata && site.siteMetadata.social
  return (
    <div className="sm:text-left sm:justify-left space-x-4 font-bold text-xl my-2 mx-auto sm:mx-12">
      <a className="inline-flex" href={`https://github.com/${github}`}>
        <FaGithub color="white" />
      </a>
      <a className="inline-flex" href={`https://linkedin.com/in/${linkedin}`}>
        <FaLinkedinIn color="white" />
      </a>
    </div>
  )
}

export default Social

const query = graphql`
  query MyQuery {
    site {
      siteMetadata {
        social {
          github
          linkedin
        }
        siteUrl
        author
      }
    }
  }
`
