import React, { ReactElement } from "react"
import { Helmet } from "react-helmet"
// import Card from "../components/Card"
//import Body from "../components/Body"
import Header from "../components/Header"
import Pic from "../components/Pic"
import Social from "../components/Social"
import Text from "../components/Text"

interface Props {}

function Index(_props: Props): ReactElement {
  return (
    <div className="text-center h-full bg-gradient-to-r from-oxford to-dazz  ">
      <Helmet>
        <meta charSet="utf-8" />
      </Helmet>
      <Header />
      <div className="flex flex-col">
        <div className="h-36"></div>
        {/* <div className="grid sm:grid-rows-2 grid-flow-col gap-4"> */}
        <div className="grid grid-cols-1 sm:grid-cols-2 sm:grid-rows-2 sm:grid-flow-col gap-4">
          <Text />
          <Pic />
        </div>
      </div>

      {/*
      <Body />
       <Card
        alt="Sunset in the mountains"
        text="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil."
        title="The Coldest Sunset"
      />
      <Card
        alt="Sunset in the mountains"
        text="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil."
        title="The Coldest Sunset"
      /> */}
    </div>
  )
}

export default Index
