import { StaticImage } from "gatsby-plugin-image"
import React, { FC } from "react"

const Pic: FC<any> = () => {
  return (
    <div className="text-center col-span-2 row-span-2 sm:text-right place-self-end">
      <StaticImage
        className="w-2/3 sm:w-2/5"
        src="../../images/profipic-web-flip.png"
        alt={"profi-pic"}
      />
    </div>
  )
}

export default Pic
